/**
 * Global variables
 *
 * If adding to this, make sure to restart webpack when in development
 * Otherwise values will not populate in css.
 *
 * Naming convention - [css attribute][semantic component being modified][optional modifier]
 */

const Color = require('microcomponents/colors')

const offWhite = '#F4F4F4'
const tooltipWhite = '#F4F2ED'
const superLightGray = '#ECECEC'
const lightGray = '#E5E6E7'
const lightMediumGray = '#D0D0D0'
const midGray = '#666666'
const charcoalGray = '#373838'
const softGray = '#D7D7D7'
const greyBorder = '#E8E9EA'

const blazeUpBlack = '#5E5F5F'
const heroBlue = '#00AAE7'
const heroBlueLight = 'rgba(27, 158, 204, 0.4)'

const oceanBlue = '#126CAB'
const melon = '#42bc97'
const fadedMelon = '8ED7C1'
const fire = '#F05b4b'
const cbdRed = '#9360A8'
const accessoryGrey = '#5E5F5F'
const mediumFire = '#F69D93'
const lightFire = '#FCDEDB'
const disabledGray = '#D8D8D8'
const smokeGray = '#7E8487'
const white = '#FFFFFF'
const beige = '#FFF8F5'
const fadedEazeBlue = '#CCEEFA'
const sativaYellow = '#EBB22D'

const normalFontSize = '1.6rem'
const headerFontSize = '2.4rem'
const labelFontSize = '1.2rem'
const placeholderFontSize = '1.4rem'
const subtitleFontSize = '1.4rem'
const leftMarginOffset = '2%'
const defaultBorderRadius = '0.4rem'
const imageBorderRadius = '0.34rem'
const capLetterSpacing = '0.04rem'
const paddingAutocomplete = '4rem'
const paddingContainer = '1rem'
const marginPageContentTop = '2rem'
const marginPageContentSide = '2rem'
const marginPageContentBottom = '2rem'
const marginFormElementsVertical = '0.5rem'
const secondaryCbdRed = '#BEA0CB'
const secondaryHybridGreen = '#8ED7C1'
const secondaryIndicaBlue = '#71A7CD'
const secondarySativaYellow = '#FCDF74'
const transparentCbdRed = 'rgba(147, 96, 168, 0.75)'
const transparentSecondaryCbdRed = 'rgba(190, 160, 203, 0.75)'
const transparentSecondaryHybridGreen = 'rgba(97, 197, 163, 0.75)'
const transparentSecondaryIndicaBlue = 'rgba(52, 120, 157, 0.75)'
const transparentSecondarySativaYellow = 'rgba(239, 193, 86, 0.75)'
const darkerTransparentCbdRed = 'rgba(147, 96, 168, 0.9)'
const darkerTransparentSecondaryCbdRed = 'rgba(190, 160, 203, 0.9)'
const darkerTransparentSecondaryHybridGreen = 'rgba(97, 197, 163, 0.9)'
const darkerTransparentSecondaryIndicaBlue = 'rgba(52, 120, 157, 0.9)'
const darkerTransparentSecondarySativaYellow = 'rgba(239, 193, 86, 0.9)'
const fadedOffEazeBlue = '#48B1D6'
const radialGradientBlueInner = '#44BBE5'
const radialGradientBlueMiddle = '#33B0DC'
const radialGradientBlueOuter = '#1C9FCD'

const productPageDesktopWidth = '78rem'
const productPageMobileWidth = '42rem'

// used in MC
const primaryDark = '#313232'
const secondaryDark = '#373838'
const darkDivider = '#404242'
const mcGreen = '#01c08a'
const mcBlue = '#35beff'
const mcYellow = '#ecb30e'
const mcRed = '#b63353'

// /MC
const desktopNavbar = '6.5rem'
const mobileNavbar = '4.83rem'

const paleBlue = '#EBF6FA'
const blueFocus = '#1B9EFF'
const melonFocus = '#2FC9A1'
const cantaloupeFocus = '#D4A947'
const warnFocus = '#BB6478'
const buttonHoverBoxShadow = '0 4px 8px rgba(0,0,0,.2)'

const shareIconSize = '5.4rem'

const accessory1 = Color.default.accessory['1']
const accessory2 = Color.default.accessory['2']
const accessory3 = Color.default.accessory['3']
const accessory4 = Color.default.accessory['4']
const accessory5 = Color.default.accessory['5']

/*
  Best practice on iOS and mobile devices is to make buttons at least 44 x 44px
  to avoid 'fat fingering'
 */
const minimumMobileDimension = '44px'

const autosuggesth = '4rem'
const autocompleteh = '4rem'

module.exports = {
  backgroundColorButtonCta: heroBlue,
  backgroundColorContainerLight: offWhite,
  defaultBorderRadius: defaultBorderRadius,
  imageBorderRadius: imageBorderRadius,
  backgroundColorTooltip: tooltipWhite,
  backgroundColorErrorRed: fire,
  backgroundColorErrorGray: smokeGray,
  backgroundColorButtonDisabled: disabledGray,
  backgroundColorButtonFire: fire,
  backgroundColorButtonMelon: melon,
  backgroundColorButtonCantaloupe: sativaYellow,

  backgroundColorButtonPrimary: heroBlue,
  backgroundColorButtonSecondary: white,
  backgroundColorSativa: sativaYellow,
  backgroundColorHybrid: melon,
  backgroundColorCurrent: melon,
  backgroundColorCompleted: fadedMelon,
  backgroundColorIndica: oceanBlue,
  backgroundColorCBD: cbdRed,
  backgroundColorAccessory: accessoryGrey,
  backgroundColorCharcoal: charcoalGray,
  backgroundColorSmoke: smokeGray,
  backgroundColorInputIcon: paleBlue,
  backgroundColorFadedOffEazeBlue: fadedOffEazeBlue,
  blazeUpBlack,
  white,

  backgroundColorSecondaryCBD: secondaryCbdRed,
  backgroundColorSecondaryHybrid: secondaryHybridGreen,
  backgroundColorSecondaryIndica: secondaryIndicaBlue,
  backgroundColorSecondarySativa: secondarySativaYellow,
  transparentCbdRed,
  transparentSecondaryCbdRed,
  transparentSecondaryHybridGreen,
  transparentSecondaryIndicaBlue,
  transparentSecondarySativaYellow,
  darkerTransparentCbdRed,
  darkerTransparentSecondaryCbdRed,
  darkerTransparentSecondaryHybridGreen,
  darkerTransparentSecondaryIndicaBlue,
  darkerTransparentSecondarySativaYellow,

  borderButtonSecondary: heroBlue,
  borderColorCBD: cbdRed,
  borderColorHybrid: melon,
  borderColorIndica: oceanBlue,
  borderColorSativa: sativaYellow,
  borderColorBlue: heroBlue,
  borderColorToolTip: tooltipWhite,

  borderContainerLightest: superLightGray,
  borderContainerLighter: lightGray,
  borderContainerLight: lightMediumGray,
  borderContainerProduct: melon,
  buttonBorderGray: softGray,

  colorLinkBlue: heroBlue,
  colorLinkLight: lightGray,
  colorLinkWhite: white,
  colorEtaMelon: melon,
  colorTextWhite: white,

  beigeBackground: beige,

  colorTextLight: lightMediumGray,
  colorTextMedium: midGray,
  colorTextSmoke: smokeGray,
  colorTextDark: charcoalGray,

  fontSizeHeader: headerFontSize,
  fontSizeLabel: labelFontSize,
  fontSizeParagraph: normalFontSize,
  fontSizePlaceholder: placeholderFontSize,
  fontSizeTitle: headerFontSize,
  fontSizeSubtitle: subtitleFontSize,

  eazeBlue: heroBlue,
  eazeBlueLight: heroBlueLight,
  oceanBlue: oceanBlue,
  fadedEazeBlue: fadedEazeBlue,
  vibrantBlue: heroBlue,

  mediumFire: mediumFire,
  lightFire: lightFire,
  fire: fire,

  darkGrayStroke: disabledGray,

  letterSpacingLabel: capLetterSpacing,

  marginLeftOffset: leftMarginOffset,

  minHeightButtonMobile: minimumMobileDimension,
  minWidthButtonMobile: minimumMobileDimension,

  paddingAutocomplete: paddingAutocomplete,
  paddingContainer: paddingContainer,

  widthProductPageDesktop: productPageDesktopWidth,
  widthProductPageMobile: productPageMobileWidth,

  marginPageContentTop,
  marginPageContentSide,
  marginPageContentBottom,
  marginFormElementsVertical,

  desktopNavbar,
  mobileNavbar,

  blueFocus,
  melonFocus,
  cantaloupeFocus,
  warnFocus,
  buttonHoverBoxShadow,

  primaryDark,
  secondaryDark,
  darkDivider,
  mcGreen,
  mcBlue,
  mcYellow,
  mcRed,
  radialGradientBlueInner,
  radialGradientBlueMiddle,
  radialGradientBlueOuter,

  shareIconSize,
  accessory1,
  accessory2,
  accessory3,
  accessory4,
  accessory5,

  greyBorder,

  autosuggesth,
  autocompleteh
}
