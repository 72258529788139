import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import colors from 'microcomponents/colors'

const fadein = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Label = styled.label<{ float: boolean }>`
  position: absolute;
  z-index: 1;
  left: 1.1rem;
  top: ${({ float }) => (float ? '1.25rem' : '2.05rem')};
  color: ${colors.accessory[1]};
  font-weight: 200;
  animation: ${fadein} 0.3s;
  font-size: ${({ float }) => (float ? '1rem' : '1.6rem')};
  user-select: none;
  outline: none;
  cursor: pointer;
`

export const FloatLabel = styled.label<{ float: boolean }>`
  position: absolute;
  z-index: 1;
  left: 1.1rem;
  top: ${({ float }) => (float ? '1.25rem' : '1.8rem')};
  color: ${colors.accessory[1]};
  font-weight: 200;
  animation: ${fadein} 0.3s;
  font-size: ${({ float }) => (float ? '1rem' : '1.6rem')};
  user-select: none;
  outline: none;
  cursor: pointer;
`

export const A11yHiddenLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
