import { PureComponent } from 'react'

import FastDrawer from '@eaze/react-fast-drawer'
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import document from 'global/document'

import {
  defaultBorderRadius,
  fontSizeParagraph,
  white,
  widthProductPageMobile
} from '@components/style-helpers/global-variables'
import CloseButton from '@microcomponents/close-button'

import { TEST_IDS } from './test/constants.js'

import { bool, func, node, object, string } from 'prop-types'

// Pass in useWideModal if you want the wide modal we use for address modal
// Pass showCloseButton to render the default close button
// Pass in headerTitle to display a header
export default class EazeDrawer extends PureComponent {
  static propTypes = {
    useWideModal: bool,
    modalElementClass: object,
    showCloseButton: bool,
    relative: bool,
    onRequestClose: func,
    useModal: bool,
    children: node,
    containerElementClass: object,
    headerTitle: string,
    headerButtonText: string,
    headerButtonOnClick: func,
    headerButtonDisabled: bool
  }

  static defaultProps = {
    useWideModal: false,
    modalElementClass: {},
    showCloseButton: false,
    relative: false,
    onRequestClose: () => {}
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
  }

  renderCloseButton({ showCloseButton, onRequestClose, open, relative }) {
    document.body.style.overflow = open ? 'hidden' : ''

    return showCloseButton ? (
      <CloseButton relative={relative} e2eId={TEST_IDS.CLOSE_BUTTON} onClick={onRequestClose} />
    ) : null
  }

  render() {
    const {
      children,
      containerElementClass,
      modalElementClass,
      useModal,
      useWideModal,
      headerTitle,
      headerButtonText,
      headerButtonOnClick,
      headerButtonDisabled
    } = this.props

    const modalElementClasses = [
      useModal && modalCss,
      useWideModal && wideModalCss,
      modalElementClass && modalElementClass
    ]

    return (
      <ClassNames>
        {({ css }) => (
          <FastDrawer
            {...this.props}
            containerElementClass={css(containerElementClass)}
            modalElementClass={css(modalElementClasses)}
            /* We have to use `id` here b/c drag drawer won't pass data attributes */
            id={TEST_IDS.OVERLAY}>
            {headerTitle ? (
              <ModalHeader>
                {headerButtonText && (
                  <ModalHeaderButton
                    onClick={headerButtonOnClick}
                    disabled={headerButtonDisabled}
                    data-e2eid={TEST_IDS.HEADER_BUTTON}>
                    {headerButtonText}
                  </ModalHeaderButton>
                )}
                <ModalHeaderTitle data-e2eid={TEST_IDS.TITLE}>{headerTitle}</ModalHeaderTitle>
                {this.renderCloseButton({ ...this.props, relative: true })}
              </ModalHeader>
            ) : (
              this.renderCloseButton(this.props)
            )}
            {children}
          </FastDrawer>
        )}
      </ClassNames>
    )
  }
}

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ModalHeaderTitle = styled.h2`
  color: #000000;
  font-family: 'Suisse Intl';
  font-weight: 350;
  font-size: 14px;
  flex: 1;
  text-align: center;
`

const ModalHeaderButton = styled.button`
  width: auto;
  padding: 18px;
  background: transparent;
  border: none;
  color: ${(props) => (props.disabled ? 'rgba(0,0,0,0.5)' : '#00a9ed')};
  font-family: 'Suisse Int'l Mono';
  font-size: 14px;
  text-transform: uppercase;
`

const wideModalCss = css`
  outline: none;
  background: ${white};
  font-size: ${fontSizeParagraph};
  z-index: 99;
  width: 76rem;
  min-height: 47rem;
  border-top-left-radius: ${defaultBorderRadius};
  border-top-right-radius: ${defaultBorderRadius};
  will-change: transform;

  @media (max-width: 991px) and (min-width: 560px) {
    width: ${widthProductPageMobile};
    margin: 4rem auto 0;
  }

  @media (max-width: 559px) {
    width: 100%;
    max-width: 100%;
    margin-top: 6rem;
    height: 100%;
    margin-bottom: 0;
  }
`

/* used in taxes-and-fees/modal */
const modalCss = css`
  outline: none;
  background: ${white};
  font-size: ${fontSizeParagraph};
  z-index: 99;
  width: 35rem;
  padding: 4rem;
  border-radius: ${defaultBorderRadius};
  will-change: transform;

  @media (max-width: 991px) and (min-width: 560px) {
    width: ${widthProductPageMobile};
    margin: 4rem auto 0;
  }

  @media (max-width: 559px) {
    width: 100%;
    max-width: 100%;
    margin-top: 6rem;
    height: 100%;
    margin-bottom: 0;
  }
`
