import styled from '@emotion/styled'

import { TEST_IDS } from './test/constants'

import { bool, func, InferProps, string } from 'prop-types'

function CloseButton({ e2eId, onClick, relative }: InferProps<typeof CloseButton.propTypes>): JSX.Element {
  return (
    <Wrapper relative={relative} data-e2eid={e2eId || TEST_IDS.CLOSE_BUTTON} onClick={onClick} role="button">
      <Button />
    </Wrapper>
  )
}
CloseButton.propTypes = {
  e2eId: string,
  onClick: func.isRequired,
  relative: bool
}

export default CloseButton

const Button = () => (
  <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g
      transform="translate(-5.000000, -6.000000) translate(6.000000, 6.000000)"
      stroke="#353034"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M.375.375l17.25 17.25m0-17.25L.375 17.625" />
    </g>
  </svg>
)

const Wrapper = styled.div<{ relative?: boolean }>`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  top: 0;
  right: 0;
  z-index: 12;
  cursor: pointer;
  padding: 1.5rem;
  ${({ relative }) => relative && 'padding-left: 43px;'}
`
